import 'babel-polyfill';
import svg4everybody from 'svg4everybody'; 
import Swiper from 'swiper';

svg4everybody();
// import 'ninelines-ua-parser';

window.Swiper = Swiper;


// import 'babel-polyfill';

// import MagnificPopup from '../../node_modules/magnific-popup/dist/jquery.magnific-popup.min.js';

// import $ from 'jquery';
// import customSelect from 'custom-select';

// import Splide from '../../node_modules/@splidejs/splide/dist/js/splide.js'

// window.$ = $;
// window.jQuery = $;
// window.W = window;
// window.D = document;
// window.H = document.querySelector('html');
// window.B = document.querySelector('body');
// window.Swiper = Swiper;
// window.MagnificPopup = MagnificPopup;
// window.customSelect = customSelect;
// window.Splide = Splide;





